{
  "name": "mokuro",
  "version": "0.1.0",
  "dependencies": {
    "@codedread/bitjs": "^1.0.6",
    "idb-keyval": "6.2.0",
    "mime": "3.0.0",
    "natsort": "^2.0.3",
    "panzoom": "9.4.3"
  },
  "devDependencies": {
    "parcel": "^2.9.3",
    "@parcel/config-default": "^2.9.3",
    "@parcel/core": "^2.9.3",
    "@parcel/service-worker": "^2.9.3",
    "@parcel/transformer-inline-string": "^2.9.3",
    "path-browserify": "^1.0.1",
    "process": "^0.11.10",
    "parcel-reporter-static-files-copy": "^1.5.0"
  },
  "alias": {
    "bitjs": "./lib/bitjs"
  },
  "staticFiles": {
    "staticPath": "./assets/demo",
    "staticOutPath": "demo"
  }
}
